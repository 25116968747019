
import Vue from "vue";
import ProfileData from "@/components/Profile/ProfileData.vue";
import PostsContainer from "@/components/Posts/PostsContainer.vue";
import UsersPanel from "@/components/Mentoree/UsersPanel.vue";
import { mapGetters } from "vuex";
import PostsContainerLoading from "@/components/Posts/PostsContainerLoading.vue";
import ProfileDataLoading from "@/components/Profile/ProfileDataLoading.vue";
import ReportUserModal from "@/components/Modals/ReportUserModal.vue";
import LoadMore from "@/components/Partials/LoadMore.vue";

export default Vue.extend({
  name: "MentoreeProfilePage",
  components: {
    ProfileData,
    PostsContainer,
    UsersPanel,
    ProfileDataLoading,
    PostsContainerLoading,
    ReportUserModal,
    LoadMore
  },
  data() {
    return {
      loadingProfile: true,
      loading: true,
      tabs: ["Recent", "Post", "Mentorcast"],
      currentTab: "Recent",
      data: [] as any,
      page: 1,
      load_more: false
    };
  },
  computed: {
    ...mapGetters({
      mentoree: "mentee/getMentee",
      mentoredBy: "mentee/getMentoredBy"
    })
  },
  mounted(): void {
    this.loadingProfile = true;
    this.getMentee().then(() => {
      this.loadingProfile = false;
      this.$store.dispatch("mentee/GET_MENTORED_BY", this.$route.params.id);
      this.getActivity();
    });
  },
  methods: {
    getMentee() {
      return this.$store.dispatch("mentee/GET_MENTEE", {
        id: this.$route.params.id
      });
    },
    onTab(tab: any) {
      this.data = [];
      this.currentTab = tab;
      this.getActivity();
    },
    onLoadMore() {
      this.page++;
      this.getActivity(this.page);
    },
    getActivity(page = 1) {
      this.loading = true;
      if (page == 1) {
        this.data = [];
      }
      this.$store
        .dispatch("mentee/GET_ACTIVITY", {
          id: this.$route.params.id,
          tab: this.currentTab,
          page: page
        })
        .then(data => {
          this.loading = false;
          this.page = page;
          this.load_more = page < data.last_page;
          this.data.push(...data.data);
        });
    },
    updateLike(post_id: any, counts: any) {
      const index = this.data.findIndex((i: any) => i.id == post_id);

      if (index > -1) {
        this.data[index].recentable.like_counts = counts;
      }
    }
  }
});
