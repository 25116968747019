
import Vue from "vue";
import PostContainer from "@/components/Posts/PostContainer.vue";
// import Post from "@/components/Posts/Post.vue";

export default Vue.extend({
  name: "PostsContainer",
  components: {
    // Post,
    PostContainer
  },
  props: {
    posts: {
      type: Array
    }
  },
  mounted() {
    console.log(this.posts);
  },
  methods: {
    updateLike(post_id: any, counts: any) {
      this.$emit("updateLike", post_id, counts);
    },
    refreshPosts() {
      this.$emit("refreshPosts");
    }
  }
});
